import './App.css';
import React, {Suspense} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Navbar from './Pages/Home/Navbar';
import Home from './Pages/Home/Homescreen';
// const Home = lazy(()=> import('./Pages/Home/Homescreen'));

function App() {
  return (
    <div className="App">
      <Router>
        <div>
          <Navbar />
          <Suspense fallback={<h1>Loading...</h1>}>
            <Routes>
              <Route path="/" element={<Home />}></Route>
              <Route path="*" element={<div>404 Not Found</div>}></Route>
            </Routes>
          </Suspense>
        </div>
      </Router>
    </div>
  );
}

export default App;
