import React, { Suspense } from "react";

const AboutMe = React.lazy(() => import("../AboutMe.jsx"));
const ContactMe = React.lazy(() => import("../ContactMe.jsx"));
const HeroSection = React.lazy(() => import("../HeroSection.jsx"));
const MyPortfolio = React.lazy(() => import("../MyPortfolio.jsx"));
const Testimonials = React.lazy(() => import("../Testimonials.jsx"));

export default function Home() {
return (
<div>
<Suspense fallback={<div>Loading</div>}>
<HeroSection />
</Suspense>
<Suspense fallback={<div>Loading</div>}>
<AboutMe />
</Suspense>

<Suspense fallback={<div>Loading</div>}>
<Testimonials/>
</Suspense>
<Suspense fallback={<div>Loading</div>}>
<MyPortfolio />
</Suspense>
<Suspense fallback={<div>Loading</div>}>
<ContactMe />
</Suspense>
</div>
);
}
